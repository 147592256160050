import { Box } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { DoneAll, RemoveShoppingCart } from '@material-ui/icons';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MoneyIcon from '@material-ui/icons/Money';
import AirlineSeatFlat from '@material-ui/icons/AirlineSeatFlat';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import ServicesIcon from '@material-ui/icons/Build';
import BusinessIcon from '@material-ui/icons/Business';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DiscFullOutlined from '@material-ui/icons/DiscFullOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import GetAppIcon from '@material-ui/icons/GetApp';
import Group from '@material-ui/icons/Group';
import HelpIcon from '@material-ui/icons/Help';
import CountriesGroupIcon from '@material-ui/icons/Language';
import LinkIcon from '@material-ui/icons/Link';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalAtm from '@material-ui/icons/LocalAtm';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import InstanceIcon from '@material-ui/icons/Public';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SubjectIcon from '@material-ui/icons/Subject';
import ToggleOn from '@material-ui/icons/ToggleOn';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { push } from 'connected-react-router';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRouter } from '../../store/system/selectors';
import pageLinks from '../../utils/pageLinks';
import Can, { Permissions } from '../Can';
import useStyles from './styles';

interface IProps {
  open: boolean;
  setOpen(param: boolean): void;
}

interface IMenu {
  name: string;
  route: string;
  getIcon(): React.ReactNode;
  perform?: string;
}

const menuItems: IMenu[] = [
  {
    route: pageLinks.home,
    name: 'Products',
    perform: Permissions.readProductList,
    getIcon: () => <FeaturedPlayListIcon />,
  },
  {
    route: pageLinks.bundleGroups,
    name: 'Plan Grouping',
    perform: Permissions.readBundleGroupList,
    getIcon: () => <Group />,
  },
  {
    route: pageLinks.promoDiscounts,
    perform: Permissions.readPromoDiscountList,
    name: 'Promo Discounts',
    getIcon: () => <DiscFullOutlined />,
  },
  {
    route: pageLinks.admins,
    perform: Permissions.readAdminList,
    name: 'Admin Users',
    getIcon: () => <PeopleAltIcon />,
  },

  {
    route: pageLinks.legalDocuments,
    perform: Permissions.readLegalDocuments,
    name: 'Legal Documents',
    getIcon: () => <ListAltIcon />,
  },

  {
    route: pageLinks.orders,
    perform: Permissions.readOrderList,
    name: 'Orders',
    getIcon: () => <ShoppingCartIcon />,
  },
  {
    route: pageLinks.declineOrders,
    perform: Permissions.readOrderList,
    name: 'Decline orders',
    getIcon: () => <RemoveShoppingCart />,
  },
  {
    route: pageLinks.currencyConverter,
    perform: Permissions.readCurrencyConverterList,
    name: 'Currency Converter',
    getIcon: () => <MonetizationOnIcon />,
  },
  // { route: pageLinks.customers, name: 'Customers', getIcon: () => <PeopleIcon /> },
  {
    route: pageLinks.exchangeRateList,
    name: 'Exchange rate',
    perform: Permissions.readAdminRoleList,
    getIcon: () => <MoneyIcon />,
  },
  {
    route: pageLinks.companyInfo,
    name: 'Company Info',
    perform: Permissions.readCompanyInfoList,
    getIcon: () => <BusinessIcon />,
  },
  {
    route: pageLinks.coupons,
    name: 'Coupons',
    perform: Permissions.readCouponReport,
    getIcon: () => <BookmarksIcon />,
  },

  {
    route: pageLinks.countriesGroups,
    name: 'Countries Groups',
    perform: Permissions.createCountriesGroup,
    getIcon: () => <CountriesGroupIcon />,
  },
  {
    route: pageLinks.mindhubGroups,
    name: 'Mindhub Groups',
    perform: Permissions.readMindhubGroup,
    getIcon: () => <AccountTreeIcon />,
  },
  {
    route: pageLinks.installmentsOrders,
    name: 'Installments orders',
    perform: Permissions.readSupportApprovalOrderList,
    getIcon: () => <BusinessIcon />,
  },
  {
    route: pageLinks.membershipSettings,
    name: 'Membership Settings',
    perform: Permissions.readMembershipLevelList,
    getIcon: () => <TrendingUpIcon />,
  },
  {
    route: pageLinks.recurringTypes,
    name: 'Recurring Types',
    perform: Permissions.readRecurringType,
    getIcon: () => <AirlineSeatFlat />,
  },
  {
    route: pageLinks.notifications,
    name: 'Notifications',
    perform: Permissions.readMindhubGroup,
    getIcon: () => <NotificationsActiveIcon />,
  },
  {
    route: pageLinks.faqList,
    name: 'FAQ',
    perform: Permissions.readFaqList,
    getIcon: () => <HelpIcon />,
  },
  {
    route: pageLinks.adminsLogs,
    name: 'Admins logs',
    perform: Permissions.readAdminsLogs,
    getIcon: () => <RecentActorsIcon />,
  },
  {
    route: pageLinks.customersLogs,
    name: 'Customers logs',
    perform: Permissions.readCustomersLogs,
    getIcon: () => <SubjectIcon />,
  },

  {
    route: pageLinks.permissions,
    name: 'Permission roles',
    perform: Permissions.readAdminRoleList,
    getIcon: () => <ErrorOutlineIcon />,
  },
  {
    route: pageLinks.instances,
    name: 'Instances',
    perform: Permissions.readInstanceList,
    getIcon: () => <InstanceIcon />,
  },
  {
    route: pageLinks.kyc,
    name: 'KYC',
    perform: Permissions.readKycVerificationListLevel1,
    getIcon: () => <VerifiedUserIcon />,
  },
  {
    route: pageLinks.payments,
    name: 'Payments',
    perform: Permissions.readPayments,
    getIcon: () => <PaymentIcon />,
  },
  {
    route: pageLinks.moneySpending,
    name: 'Money Spending',
    perform: Permissions.readBaseSpending,
    getIcon: () => <LocalAtm />,
  },
  {
    route: pageLinks.bundleSwitchSubscriptionRule,
    name: 'Bundle Switch Rule',
    perform: Permissions.readSwitchingRules,
    getIcon: () => <ToggleOn />,
  },
  {
    route: pageLinks.manageUserBundle,
    name: 'Manage user bundles',
    perform: Permissions.readSwitchingRules,
    getIcon: () => <RotateLeft />,
  },
  {
    route: pageLinks.triggerEvents,
    name: 'Trigger Events',
    perform: Permissions.readProductList,
    getIcon: () => <RotateRightIcon />,
  },
  {
    route: pageLinks.whiteList,
    name: 'Whitelist',
    perform: Permissions.readIpWhitelist,
    getIcon: () => <PlaylistAddCheckIcon />,
  },
  {
    route: pageLinks.productsActivations,
    name: 'Products Activations',
    perform: Permissions.readCustomerList,
    getIcon: () => <DoneAll />,
  },
  {
    route: pageLinks.umustlookSettings,
    name: 'Umustlook Settings',
    perform: Permissions.readUmustlookItem,
    getIcon: () => <LinkIcon />,
  },

  {
    route: pageLinks.services,
    name: 'Services',
    perform: Permissions.readServiceList,
    getIcon: () => <ServicesIcon />,
  },
  {
    route: pageLinks.settings,
    name: 'Settings',
    perform: Permissions.readSettings,
    getIcon: () => <SettingsIcon />,
  },
];

const downMenu: IMenu[] = [
  {
    route: pageLinks.csvReport,
    name: 'Payout Export',
    perform: Permissions.exportPayoutList,
    getIcon: () => <ListAltIcon />,
  },
  {
    route: pageLinks.customerPayouts,
    name: 'Customer Payouts',
    perform: Permissions.readCustomerList,
    getIcon: () => <ReceiptIcon />,
  },
  {
    route: pageLinks.invoices,
    name: 'Invoices',
    perform: Permissions.downloadSignedDocs,
    getIcon: () => <ReceiptIcon />,
  },
  {
    route: pageLinks.signedDocuments,
    name: 'Signed Documents',
    perform: Permissions.downloadSignedDocs,
    getIcon: () => <ReceiptIcon />,
  },
  {
    route: pageLinks.userMigrationsReport,
    name: 'Reports',
    perform: Permissions.generateUserMigrationsReport,
    getIcon: () => <GetAppIcon />,
  },
  {
    route: pageLinks.userKycReport,
    name: 'Customers KYC',
    perform: Permissions.generateUserKycReport,
    getIcon: () => <PlaylistAddCheckIcon />,
  },
  {
    route: pageLinks.massPaymentRequests,
    name: 'Mass Payout',
    perform: Permissions.massPayoutList,
    getIcon: () => <FormatListNumberedIcon />,
  },
];

const MenuSkeleton = () => (
  <ListItem>
    <ListItemIcon>
      <Skeleton animation="wave" variant="circle" width={24} height={24} />
    </ListItemIcon>
    <Skeleton height={32} width={'100%'} variant={'text'} />
  </ListItem>
);

const Menu: React.FC<IProps> = ({ setOpen, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const {
    location: { pathname },
  } = useSelector(selectRouter);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const moveToPage = (route: string): void => {
    dispatch(push(route));
    setSearch('');
  };

  const listWithSearch = useMemo(() => {
    if (search.length) {
      return menuItems.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    return menuItems;
  }, [search]);

  const downMenuWithSearch = useMemo(() => {
    if (search.length) {
      return downMenu.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
    }
    return downMenu;
  }, [search]);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <Box className={classes.boxSearch}>
        <SearchIcon />
        <InputBase
          placeholder={'Search…'}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputProps={{ 'aria-label': 'search' }}
          fullWidth
        />
      </Box>
      <Divider />

      <List>
        {listWithSearch.map(({ getIcon, name, route, perform }) => (
          <Can perform={perform} key={name} no={() => <MenuSkeleton />}>
            <ListItem button onClick={() => moveToPage(route)} selected={pathname === route}>
              <ListItemIcon>{getIcon()}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </Can>
        ))}
      </List>
      <Divider />
      <List>
        <ListSubheader inset>Saved reports</ListSubheader>
        {downMenuWithSearch.map(({ getIcon, name, route, perform }) => (
          <Can perform={perform} key={name} no={() => <MenuSkeleton />}>
            <ListItem button onClick={() => moveToPage(route)} selected={pathname === route}>
              <ListItemIcon>{getIcon()}</ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          </Can>
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
