import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import { useMemo } from 'react';
import { IMembershipLevel } from '../../services/api/requests/membershipLevels';
import { IMembershipRule } from '../../services/api/requests/membershipRules';
import Can, { Permissions } from '../Can';
import { CustomTableHead, useStandardTableStyles } from '../DefaultTable';
import DeleteModalButton from '../DeleteModalButton';
import TableSkeleton from '../TableSkeleton';

const headCells = [
  {
    id: 'membershipLevelFromId',
    numeric: false,
    disablePadding: false,
    label: 'From',
    disableSort: true,
  },
  {
    id: 'membershipLevelToId',
    numeric: false,
    disablePadding: false,
    label: 'To',
    disableSort: true,
  },
  { id: 'edit', numeric: false, disablePadding: false, label: '', disableSort: true },
  { id: 'delete', numeric: false, disablePadding: false, label: '', disableSort: true },
];

interface IProps {
  loading: boolean;
  rules: IMembershipRule[] | null;
  handleEdit: (item: IMembershipRule) => void;
  handleDelete: (id: string | number) => void;
  levels: IMembershipLevel[] | null;
}

const MembershipRulesTable = (props: IProps) => {
  const { loading, rules, handleEdit, handleDelete, levels } = props;
  const classes = useStandardTableStyles();
  const getLevelNameById = (id: string | number) => {
    if (levels) {
      return levels.find((l) => l.id === +id)?.name;
    }
    return id;
  };

  const skuHeaders = useMemo(() => {
    const sortedRules = rules?.sort(
      (a, b) => Object.keys(b.skus).length - Object.keys(a.skus).length
    );
    const maxSkus = Object.keys(sortedRules?.[0].skus || {});
    const skus = maxSkus.length ? maxSkus : ['Annual', 'Monthly'];
    return skus.sort().map((item) => ({
      id: item,
      numeric: false,
      disablePadding: false,
      label: item + ' SKU',
      disableSort: true,
    }));
  }, [rules]);

  return (
    <TableContainer>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={'medium'}
        aria-label="enhanced table"
      >
        <CustomTableHead headCells={[...skuHeaders, ...headCells]} classes={classes} />
        <TableBody>
          {loading && <TableSkeleton />}
          {!loading && !rules?.length ? (
            <TableRow>
              <TableCell>There are no membership rules</TableCell>
            </TableRow>
          ) : (
            !loading &&
            rules?.map((item) => (
              <TableRow key={item.id}>
                {skuHeaders?.map(({ id }) => (
                  <TableCell key={id}>{item.skus?.[id] || '-'}</TableCell>
                ))}
                <TableCell>{getLevelNameById(item.membershipLevelFromId)}</TableCell>
                <TableCell>{getLevelNameById(item.membershipLevelToId)}</TableCell>
                <TableCell align="center">
                  <Can perform={Permissions.updateUpgradeMembershipRule}>
                    <IconButton aria-label="edit" title={'Edit'} onClick={() => handleEdit(item)}>
                      <EditIcon />
                    </IconButton>
                  </Can>
                </TableCell>
                <TableCell align="center">
                  <Can perform={Permissions.deleteUpgradeMembershipRule}>
                    <DeleteModalButton
                      name={`Rule ${item.skus?.Monthly || '-'} / ${item.skus?.Annual || '-'}`}
                      entity={'membership rules'}
                      onDelete={() => handleDelete(item.id)}
                    />
                  </Can>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MembershipRulesTable;
