import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { OrderSearch } from '@root/components/OrdersSearch';
import RefundOrderModal from '@root/components/RefundOrderModal';
import TableSkeleton from '@root/components/TableSkeleton';
import Title from '@root/components/Title';
import { headCells } from '@root/pages/Orders/static-data';
import { useStyles } from '@root/pages/Orders/styles';
import { SortOptions } from '@root/pages/Orders/types';
import { useOrders } from '@root/pages/Orders/use-orders';
import { IRefundOrder, markAsRefunded } from '@root/services/api/requests/orders';
import React from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { CustomTableHead, useStandardTableStyles } from '../../components/DefaultTable';

export default function EnhancedTable() {
  const classes = { ...useStandardTableStyles(), ...useStyles() };
  const dateFns = new DateFnsUtils();
  const [state, handlers] = useOrders();

  const handleRequestSort = (_: any, property: SortOptions) => {
    const isAsc = state.sortBy === property && state.sortDirection === 'asc';
    handlers.setDirection(isAsc ? 'desc' : 'asc');
    handlers.setSortBy(property);
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    handlers.setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    handlers.setRowsPerPage(parseInt(event.target.value, 10));
    handlers.setPage(1);
  };

  const handleRefund = async (data: IRefundOrder) => {
    try {
      await markAsRefunded(data);
      handlers.fetchList();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid className={classes.root}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div className={classes.paperHeader}>
            <Title>Orders</Title>
          </div>
        </Paper>
      </Grid>

      <Paper className={classes.paper}>
        <OrderSearch state={state} handlers={handlers} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <CustomTableHead
              classes={classes}
              order={state.sortDirection}
              orderBy={state.sortBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {state.loading && <TableSkeleton />}

              {!state.loading &&
                state.ordersList?.map((order) => {
                  const {
                    createdAt,
                    items,
                    type,
                    amount,
                    membership,
                    status,
                    user: { username, id: userId },
                    id,
                    transactions,
                  } = order;

                  const itemWithId = items.find((item) => !!Number(item.exigoOrderId));
                  const name = items.map((item) => item.level).join(', ');
                  const createDate = dateFns.format(new Date(createdAt), 'dd/MM/yyyy hh:mm');
                  const paymentMethod = transactions.map((item) => item.paymentMethod).join(', ');
                  const transactionsId = transactions.map((item) => item.id).join('\n');

                  return (
                    <TableRow key={id}>
                      <TableCell align="left">{id}</TableCell>
                      <TableCell align="left">{createDate}</TableCell>
                      <TableCell align="left">
                        {username}/{userId}
                      </TableCell>
                      <TableCell align="left">
                        {(itemWithId && itemWithId.exigoOrderId) || '------'}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'pre-line' }} align="left">
                        {transactionsId}
                      </TableCell>
                      <TableCell align="left">{type}</TableCell>
                      <TableCell align="left">
                        {name}
                        {membership ? `/${membership}` : ''}
                      </TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{paymentMethod}</TableCell>
                      <TableCell>
                        <div className={classes.status}> {renderStatus(status)}</div>
                      </TableCell>
                      <TableCell align="center">
                        <RefundOrderModal
                          message={order.message}
                          status={order.status}
                          onOk={(description) => handleRefund({ id, description })}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!state.loading && !state.ordersList?.length && (
                <TableRow>
                  <TableCell>
                    There are no orders{' '}
                    {state.searchTerm.length > 0 ? `for current search - ${state.searchTerm} ` : ''}{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 25]}
          component="div"
          count={state.meta.total}
          rowsPerPage={state.meta.limit}
          page={state.meta.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
}

const renderStatus = (status: string) => {
  if (['Complited', 'Paid', 'Delivered'].includes(status)) {
    return <div className="status green">{status}</div>;
  }

  if (status === 'On hold' || status === 'Refunded') {
    return <div className="status blue">{status}</div>;
  }

  if (status === 'Canceled' || status === 'Error') {
    return <div className="status red">{status}</div>;
  }

  if (status === 'Blockchain Pending') {
    return (
      <div className="status orange blockchain">
        <div>Blockchain</div>
        <div>Pending</div>
      </div>
    );
  }

  return <div className="status orange">{status}</div>;
};
