import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SAGE_PROVIDER_ROLES } from '@root/components/CreateEditService/types';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import { History, Location } from 'history';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import BulletDescriptions from '../../components/BulletDescriptions';
import Can, { Permissions } from '../../components/Can';
import CountrySelect from '../../components/CountrySelect';
import FileInput from '../../components/FileInput';
import { createInput, ICreateInputFields, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import MultipleFileInput from '../../components/MultipleFileInput';
import { RichEditorField } from '../../components/RichEditor/rich-editor-field';
import SKUSelect from '../../components/SKUSelect';
import SwitchingRulesList from '../../components/SwitchingRulesList';
import Title from '../../components/Title';
import UpsellingRulesList from '../../components/UpsellingRulesList';
import { IMAGE_UPLOAD_ENTITY } from '../../constants';
import { RECURRING_TYPES } from '../../enums/recurring-type';
import createProductPlan, { ICreatePlan } from '../../services/api/requests/createProductPlan';
import editProductPlan, { IAttachment } from '../../services/api/requests/editProductPlan';
import { PLAN_RECURRING_CHARGE_TYPE } from '../../services/api/requests/getProductPlans';
import { getAvailableServices, IServiceResponse } from '../../services/api/requests/getServices';
import { IImageProps } from '../../services/api/requests/uploadMultipleImageAtachments';
import { editPlan, setPurchaseRules } from '../../store/entities/actions';
import {
  selectCountries,
  selectPaymentMethodOptions,
  selectPlanForEditing,
  selectRecurringTypes,
  selectServices,
  selectServicesOptions,
} from '../../store/entities/selectors';
import { IOption } from '../../utils/commonTypes';
import { getSearchParam, getTextFieldLength, shopTypeOptions } from '../../utils/helpers';
import { setSuccessNotification } from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';
import { SAGE_ROLES } from './types';

const requiredMessage = 'This field is required';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(requiredMessage).max(40, "Value can't be more than 40"),
  titleDescription: Yup.string().max(200, "Value can't be more than 200"),
  saleShopType: Yup.string().required(requiredMessage),
  fullDescription: Yup.string().max(3000, "Value can't be more than 3000"),
  description: Yup.string().max(1500, "Value can't be more than 1500"),
  recurringSKU: Yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'These characters are not supported'),
  primaryServiceId: Yup.string().required(requiredMessage),
  // countryCodes: Yup.array().min(1).required(requiredMessage),
  color: Yup.string().required(requiredMessage),
  bgColor: Yup.string().required(requiredMessage),
  rebillyPlanId: Yup.string().required(requiredMessage),
  discountExigoItem: Yup.string().required(requiredMessage),
  priority: Yup.string().required(requiredMessage),
  uiPriority: Yup.string().required(requiredMessage),
  setupPrice: Yup.number().required(requiredMessage),
  cv: Yup.number().required(requiredMessage),
  fsb: Yup.number().required(requiredMessage),
  qv: Yup.number().required(requiredMessage),
  scv: Yup.number().required(requiredMessage),
  sqv: Yup.number().required(requiredMessage),
  isCashbackActive: Yup.boolean(),
  enrollerCashback: Yup.number()
    .min(0, "Value can't be less than 0")
    .max(100, "Value can't be more than 100")
    .required(requiredMessage),
  customerCashback: Yup.number()
    .min(0, "Value can't be less than 0")
    .max(100, "Value can't be more than 100")
    .required(requiredMessage),
  trialPeriod: Yup.number()
    .min(0, "Value can't be less than 0")
    .max(90, "Value can't be more than 90"),

  splitBaseValue: Yup.number().min(0, "Value can't be less than 0"),
  splitPeriod: Yup.number(),
  // splitBaseRenewValue: Yup.number().min(0, "Value can't be less than 0"),
  splitFSB: Yup.number().when('splitValues', (splVal: number) => {
    if (splVal) {
      return Yup.number().test(
        'has-min-required-characters',
        'Should be the same as SplitValues or 0 and shouldn\'t be equal "1"',
        (fsb) => {
          const splitValue = Number(splVal);
          if (fsb === 1) {
            return false;
          } else if (!fsb) {
            return true;
          }
          return splitValue === fsb;
        }
      );
    }

    return Yup.number()
      .min(0, "Value can't be less than 0")
      .test('has-min-required-characters', 'Shouldn\'t be equal "1"', (val) => val !== 1);
  }),

  splitValues: Yup.number(),
  // .min(0, "")
  // .test("has-min-required-characters", "Shouldn't be equal \"1\"", (val) => val !== 1)
});

const getValuesFromEditedPlan = (plan: ICreatePlan, isCreate: boolean): ICreatePlan => {
  const data = { ...initialValues } as ICreatePlan;
  for (let key in plan) {
    if (plan.hasOwnProperty(key) && plan[key as keyof ICreatePlan] !== null) {
      //@ts-ignore
      data[key as typeof key] = plan[key];
    }
  }

  if (isCreate) {
    editingRules.regional.forEach((key) => {
      // @ts-ignore
      data[key] = initialValues[key];
    });
  }

  return data;
};

const filterVideos = (files?: IAttachment[]) =>
  files?.filter((file) => file.type === 'video/mp4') || [];

const filterImages = (files?: IAttachment[]) =>
  files?.filter((file) => file.type !== 'video/mp4') || [];

const editingRules = {
  regional: [
    'name',
    'renewalOptions',
    'countryCodes',
    'setupPrice',
    'cv',
    'qv',
    'scv',
    'matrixBV',
    'sqv',
    'fsb',
    'status',
    'recurringPrice',
    'rcv',
    'rqv',
    'rscv',
    'rsqv',
    'rfsb',
    'rmatrixBV',
    'uiOriginalPrice',
    'addSageRoles',
    'splitSKU',
    'splitBaseValue',
    'splitValues',
    'splitFSB',
    'splitBaseRenewValue',
    'serviceIDs',
    'splitPeriod',
    'ghlTag',
  ],
};

const initialValues: ICreatePlan = {
  name: '',
  titleDescription: '',
  renewalOptions: [1],
  saleShopType: '',
  recurringSKU: '',
  sku: '',
  countryCodes: [],
  additionalInfo: '',
  color: '#000',
  bullets: [],
  bgColor: '#000',
  currency: 'USD',
  description: '',
  discountExigoItem: '',
  fullDescription: '',
  file: {},
  intervalSize: 30,
  intervalUnit: 'day',
  priority: 1,
  fsbEnabled: false,
  autopayable: false,
  membershipDiscountAllowed: false,
  rewardingDays: 0,
  noExigo: false,
  rebillyPlanId: '',
  rcv: 0,
  rfsb: 0,
  recurringPrice: 0,
  rqv: 0,
  recurringRetailPrice: 0,
  isRecurring: false,
  recurringChargeType: PLAN_RECURRING_CHARGE_TYPE.DEFAULT,
  icon: '',
  hasRetail: false,
  rscv: 0,
  matrixBV: 0,
  rmatrixBV: 0,
  rsqv: 0,
  retailPrice: 0,
  setupPrice: 0,
  serviceIDs: [],
  cv: 0,
  fsb: 0,
  qv: 0,
  scv: 0,
  sqv: 0,
  splitValues: 0,
  splitFSB: 0,
  splitSKU: '',
  splitBaseValue: 0,
  splitPeriod: 0,
  regionalDatasets: [],
  retailExigoItem: '',
  retailRecurringCV: 0,
  retailRecurringQV: 0,
  oneDayRecurringSKU: '',
  retailRecurringSCV: 0,
  retailRecurringSQV: 0,
  retailRecurringFSB: 0,
  gatewayMethodIds: [],
  status: 'active',
  imageUrl: null,
  attachmentIds: null,
  attachments: [],
  requireVerification: true,
  tLevelJoin: false,
  isSage: false,
  primaryServiceId: '',
  enrollerCashback: 0,
  customerCashback: 0,
  isCashbackActive: false,
  recurringType: RECURRING_TYPES.MONTHLY,
  uiPriority: 1,
  isRegional: false,
  resignDate: null,
  addSageRoles: [],
  splitBaseRenewValue: 0,
  stripeCustomPlanId: null,
  exigoField1: null,
  trialPeriod: 0,
  uiOriginalPrice: 0,
  ghlTag: '',
};

const CreateEditPlan: React.FC<{
  match: match;
  history: History;
  location: Location;
  staticContext: any;
}> = () => {
  const classes = useStandardFormStyles();
  const planForEditing = useSelector(selectPlanForEditing);
  const servicesList = useSelector(selectServices);
  const servicesOptions = useSelector(selectServicesOptions);
  const gateways: IOption[] = useSelector(selectPaymentMethodOptions);
  const allCountries = useSelector(selectCountries);
  const recurringTypes = useSelector(selectRecurringTypes);

  const isRegionalPlan = getSearchParam('isRegionalPlan') === 'true';
  const planGroupId = getSearchParam('planGroupId');
  const productId = getSearchParam('productId');
  const isAddon = !!getSearchParam('isAddon');
  const isCreate = getSearchParam('action') === 'create';

  const availableCountryCodes = planForEditing?.availableCountryCodes;

  const isAvailableForEditing = (rule: 'regional', field: keyof ICreatePlan) => {
    return isRegionalPlan ? !editingRules[rule].includes(field) : false;
  };

  // const purchaseRules: INormalizedPurchaseRuleSet[] | [] = useSelector(selectPurchaseRules);
  const [splitOption, setSplitOption] = useState<boolean>(!!planForEditing?.formData?.splitSKU);
  const isEdit = !!planForEditing?.formData;

  const initialPrimaryService = planForEditing?.formData?.primaryServiceId
    ? servicesOptions.filter((item) => item.value === planForEditing?.formData?.primaryServiceId)
    : [];

  const [primaryServices, setServices] = useState<IOption[]>(initialPrimaryService);

  const dispatch = useDispatch();

  const fk: FormikProps<ICreatePlan> = useFormik({
    initialValues: isEdit
      ? {
          ...getValuesFromEditedPlan(planForEditing?.formData as ICreatePlan, isCreate),
          isCashbackActive:
            planForEditing?.formData &&
            (planForEditing?.formData.enrollerCashback > 0 ||
              planForEditing?.formData?.customerCashback > 0),
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log('onSubmit', values);

      try {
        const {
          name,
          titleDescription,
          rebillyPlanId,
          priority,
          discountExigoItem,
          status,
          setupPrice,
          recurringPrice,
          bullets,
          currency,
          autopayable,
          membershipDiscountAllowed,
          rewardingDays,
          fsbEnabled,
          tLevelJoin,
          isSage,
          description,
          fullDescription,
          imageUrl,
          countryCodes,
          isRecurring,
          recurringChargeType,
          noExigo,
          icon,
          saleShopType,
          requireVerification,
          color,
          bgColor,
          serviceIDs,
          primaryServiceId,
          gatewayMethodIds,
          enrollerCashback,
          customerCashback,
          recurringType,
          splitSKU,
          splitBaseValue,
          splitPeriod,
          splitValues,
          splitFSB,
          uiPriority,
          regionalDatasets,
          resignDate,
          addSageRoles,
          splitBaseRenewValue,
          stripeCustomPlanId,
          exigoField1,
          trialPeriod,
          uiOriginalPrice,
          renewalOptions,
          ghlTag,
        } = values;

        const codes = countryCodes.includes('all')
          ? allCountries.map((item) => item.code)
          : countryCodes;

        const data = {
          recurringType,
          id: planForEditing?.id || void 0,
          renewalOptions,
          name,
          titleDescription,
          rebillyPlanId,
          priority,
          bullets,
          discountExigoItem,
          status: ['active', 'inactive'].includes(status) ? status : undefined,
          setupPrice,
          recurringPrice,
          currency,
          autopayable,
          membershipDiscountAllowed,
          rewardingDays,
          fsbEnabled,
          tLevelJoin,
          isSage,
          description,
          fullDescription,
          imageUrl,
          countryCodes: uniq(codes).filter((item) => item.length === 2),
          uiPriority,
          // @ts-ignore
          attachmentIds: values.attachments?.map((media: IImageProps) => media.id),
          pointRewards: {
            cv: values.cv,
            qv: values.qv,
            scv: values.scv,
            sqv: values.sqv,
            fsb: values.fsb,
            rcv: values.rcv,
            rqv: values.rqv,
            rscv: values.rscv,
            rsqv: values.rsqv,
            rfsb: values.rfsb,
            matrixBV: values.matrixBV,
            rmatrixBV: values.rmatrixBV,
          },
          isRecurring,
          recurringChargeType,
          noExigo,
          icon,
          saleShopType,
          requireVerification,
          color,
          bgColor,
          serviceIDs,
          primaryServiceId,
          gatewayMethodIds,
          enrollerCashback,
          customerCashback,
          splitSKU: splitSKU || null,
          splitBaseValue: splitBaseValue || null,
          splitPeriod: splitPeriod || null,
          splitValues: splitValues || null,
          splitFSB: splitFSB || null,
          regionalPlans: regionalDatasets.length ? regionalDatasets : undefined,
          planGroupId: isCreate && isRegionalPlan ? Number(planGroupId) : undefined,
          resignDate: resignDate ? resignDate : undefined,
          addSageRoles: !!addSageRoles?.length ? addSageRoles : undefined,
          splitBaseRenewValue: splitBaseRenewValue || null,
          stripeCustomPlanId,
          exigoField1,
          trialPeriod: trialPeriod || undefined,
          uiOriginalPrice: uiOriginalPrice || null,
          ghlTag: ghlTag || null,
        };

        if (isEdit && !isCreate) {
          await editProductPlan(data as any, productId as string);
        } else {
          // @ts-ignore
          await createProductPlan(data, productId as string);
        }

        dispatch(push(pageLinks.createEditProduct + `?productId=${productId}`));
        dispatch(editPlan(null));

        setSuccessNotification();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const services: IOption[] = useMemo(() => {
    let filteredServices = [];

    if (fk.values.noExigo) {
      filteredServices = servicesList?.filter(
        (service) => !Object.values(service.skus || {}).length
      );
    } else {
      filteredServices = servicesList?.filter((service) => service.skus?.[fk.values.recurringType]);
    }

    return filteredServices.map((s) => ({
      value: s.id,
      label: `${s.name} (exigoId: ${s.exigoId})`,
    }));
  }, [fk.values.recurringType, servicesList, fk.values.noExigo]);

  const fullDescriptionLength = getTextFieldLength(fk?.values?.fullDescription);
  const descriptionLength = getTextFieldLength(fk?.values?.description);

  const notAddedCountries = useMemo(() => {
    const removedCountryCodes = planForEditing?.formData.countryCodes.filter(
      (item) => !fk.values.countryCodes.includes(item)
    );

    return allCountries.filter((country) =>
      availableCountryCodes
        ? !fk.values.countryCodes.includes(country.code) &&
          // @ts-ignore
          (availableCountryCodes?.includes(country.code) ||
            removedCountryCodes?.includes(country.code))
        : !fk.values.countryCodes.includes(country.code)
    );
  }, [
    allCountries,
    fk.values.countryCodes,
    availableCountryCodes,
    planForEditing?.formData.countryCodes,
  ]);

  const handleCashbackToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) {
      fk.setFieldValue('enrollerCashback', 0);
      fk.setFieldValue('customerCashback', 0);
    }
    fk.handleChange(e);
  };

  const handleRenewPeriod = (params: number[]) => {
    const value = [...params];
    if (!value.includes(1)) {
      value.push(1);
    }
    fk.setFieldValue('renewalOptions', value.sort());
  };

  useEffect(() => {
    updateServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fk.values.serviceIDs]);

  useEffect(
    () => () => {
      dispatch(setPurchaseRules([]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateServices = async () => {
    try {
      const response: AxiosResponse<IServiceResponse> = await getAvailableServices();
      const serviceIds = fk.values.serviceIDs.map((id) => +id);
      // @ts-ignore
      const serviceOptions: IOption[] = response.data.data
        .map((s) => {
          if (serviceIds?.includes(s.id)) {
            return {
              value: s.id,
              label: `${s.name} (exigoID: ${s.exigoId})`,
            };
          } else {
            return null;
          }
        })
        .filter((s) => s);

      setServices(serviceOptions);
    } catch (e) {
      console.warn('ERROR SERVICES', e);
    }
  };

  const createEditText = isEdit && !isCreate ? 'Edit' : 'Create';

  const createField = createInput<ICreatePlan>(fk);

  //@ts-ignore
  const createInputField: ICreateInputFields<ICreatePlan> = (...rest) => {
    const [id, label, md, type, isRequired, customChangeFn, disabled, prefix, value] = rest || [];
    return createField(
      id,
      label || id,
      md,
      type,
      isRequired,
      customChangeFn,
      disabled || isAvailableForEditing('regional', id),
      prefix,
      value
    );
  };

  useEffect(() => {
    return () => {
      dispatch(editPlan(null));
    };
  }, [dispatch]);

  const handleDateChange = (date: Date | null) => {
    fk.setFieldValue('resignDate', date);
  };

  return (
    <Grid item xs={12}>
      <BackButton
        name={'Back'}
        link={pageLinks.createEditProduct + `?productId=${productId}`}
        margin={'0 0 10px 0'}
      />
      <Paper className={classes.paper}>
        <Title>{createEditText} Bundle </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Switch
                        id={'fsbEnabled'}
                        checked={fk.values.fsbEnabled}
                        onChange={(e) => {
                          fk.handleChange(e);
                          fk.setFieldValue('fsb', 0);
                          fk.values.regionalDatasets.forEach((item, index) => {
                            fk.setFieldValue(`regionalDatasets.${index}.FSB`, 0);
                          });
                        }}
                        name="fsbEnabled"
                        color="primary"
                        disabled={isAvailableForEditing('regional', 'fsbEnabled')}
                      />
                    }
                    label="FSB calculated from price"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        id={'requireVerification'}
                        checked={fk.values.requireVerification}
                        onChange={(e) => {
                          fk.handleChange(e);
                          fk.setFieldValue('requireVerification', e.target.checked);
                        }}
                        name="requireVerification"
                        color="primary"
                        disabled={isAvailableForEditing('regional', 'requireVerification')}
                      />
                    }
                    label="Require verification"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        id={'noExigo'}
                        checked={fk.values.noExigo}
                        onChange={(e) => {
                          fk.handleChange(e);
                        }}
                        name="noExigo"
                        color="primary"
                        disabled={isAvailableForEditing('regional', 'noExigo')}
                      />
                    }
                    label="Should exclude exigo flow"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        id={'tLevelJoin'}
                        checked={fk.values.tLevelJoin}
                        onChange={(e) => {
                          fk.handleChange(e);
                          fk.setFieldValue('tLevelJoin', e.target.checked);
                        }}
                        name="tLevelJoin"
                        color="primary"
                        disabled={isAvailableForEditing('regional', 'tLevelJoin')}
                      />
                    }
                    label="Join to existed T-level"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        id={'membershipDiscountAllowed'}
                        checked={fk.values.membershipDiscountAllowed}
                        onChange={fk.handleChange}
                        name="membershipDiscountAllowed"
                        color="primary"
                        disabled={
                          !isAddon || isAvailableForEditing('regional', 'membershipDiscountAllowed')
                        }
                      />
                    }
                    label="Membership Discount Allowed"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        id={'isSage'}
                        checked={fk.values.isSage}
                        onChange={(e) => {
                          fk.handleChange(e);
                          fk.setFieldValue('isSage', e.target.checked);
                        }}
                        name="isSage"
                        color="primary"
                        disabled={isAvailableForEditing('regional', 'isSage')}
                      />
                    }
                    label="Is Sage"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth variant="outlined">
                  <InputSelect
                    id="saleShopType"
                    label={'Shop type'}
                    value={fk.values.saleShopType}
                    onChange={(e) => fk.setFieldValue('saleShopType', e.target.value)}
                    options={shopTypeOptions}
                    error={!!fk.errors.saleShopType}
                    disabled={isAvailableForEditing('regional', 'saleShopType')}
                  />
                </FormControl>

                <Box mb={'15px'} />

                <FormControl fullWidth variant="outlined">
                  <InputSelect
                    id="recurringType"
                    label={'Recurring type'}
                    value={fk.values.recurringType}
                    onChange={(e) => fk.setFieldValue('recurringType', e.target.value)}
                    options={
                      recurringTypes?.map((item) => ({
                        value: item.code,
                        label: item.code,
                      })) || []
                    }
                    disabled={isAvailableForEditing('regional', 'recurringType')}
                  />
                </FormControl>

                <Box mb={'15px'} />

                <FormControl fullWidth variant="outlined">
                  <InputSelect
                    id="renewalOptions"
                    label={'Renewal Options'}
                    value={fk.values.renewalOptions}
                    // @ts-ignore
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleRenewPeriod(e.target.value as any)
                    }
                    options={[
                      { value: 1, label: '1' },
                      { value: 3, label: '3' },
                      { value: 6, label: '6' },
                    ]}
                    multiple
                    disabled={isAvailableForEditing('regional', 'renewalOptions')}
                  />
                </FormControl>
              </Grid>

              {createInputField('name', 'Name*')}
              {createInputField('titleDescription', 'Title Description', 12)}
              <Grid item xs={12} md={12}>
                <RichEditorField
                  label={`Description ${descriptionLength}`}
                  value={fk.values.description}
                  setFieldValue={(val: string) => {
                    fk.setFieldValue('description', val.replace(/"/g, "'"));
                  }}
                  error={fk.errors?.description}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <RichEditorField
                  label={`Full Description ${fullDescriptionLength}`}
                  value={fk.values.fullDescription}
                  setFieldValue={(val: string) => {
                    fk.setFieldValue('fullDescription', val.replace(/"/g, "'"));
                  }}
                  error={fk.errors?.fullDescription}
                />
              </Grid>
              {createInputField('rebillyPlanId', 'Rebilly Bundle Id*')}
              {createInputField('discountExigoItem', 'Discount Exigo Item*')}
              <Grid item xs={12}>
                <Typography color="primary">Video Attachments</Typography>
                <MultipleFileInput
                  isVideoInput
                  id={'attachments'}
                  label={'(user can add video files and previews for videos)'}
                  videos={filterVideos(fk.values.attachments)}
                  images={[]}
                  list={fk.values.attachments}
                  fk={fk}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="primary">Image Attachments</Typography>
                <MultipleFileInput
                  isVideoInput={false}
                  id={'attachments'}
                  label={'(user can add several images)'}
                  videos={[]}
                  images={filterImages(fk.values.attachments)}
                  list={fk.values.attachments}
                  fk={fk}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Modal
                  button={
                    !!notAddedCountries.length && (
                      <div
                        style={{
                          textTransform: 'capitalize',
                          textAlign: 'right',
                          cursor: 'pointer',
                        }}
                      >
                        Missed countries
                      </div>
                    )
                  }
                  renderContent={(onClose: () => void) => (
                    <div>
                      <h2> Countries </h2>
                      <h5>Please click on the country to add</h5>
                      <Box display="flex" flexWrap="wrap">
                        {notAddedCountries.map((country: any, idx: number, arr: any) => (
                          <Box m={0.5} key={country.code} display="flex" flexWrap="wrap">
                            <button
                              onClick={() =>
                                fk.setValues((prev) => ({
                                  ...prev,
                                  countryCodes: [...prev.countryCodes, country.code],
                                }))
                              }
                            >
                              {`${country.name}(${country.code})${
                                arr.length - 1 === idx ? '' : ', '
                              }`}
                            </button>
                          </Box>
                        ))}

                        {!notAddedCountries.length && (
                          <Box m={0.5} display="flex" flexWrap="wrap">
                            All the countries are added
                          </Box>
                        )}
                      </Box>
                      {notAddedCountries.length && (
                        <Box
                          display="flex"
                          paddingTop={'20px'}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              fk.setValues((prev) => ({
                                ...prev,
                                countryCodes: [
                                  ...prev.countryCodes,
                                  ...notAddedCountries.map((c: any) => c.code),
                                ],
                              }));
                              onClose();
                            }}
                          >
                            Add all
                          </Button>
                        </Box>
                      )}
                    </div>
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <CountrySelect
                  id={'countryCodes'}
                  value={fk.values.countryCodes}
                  onChange={(name, value) => {
                    fk.setFieldValue(name, value);
                  }}
                  withGroups
                  error={!!fk.errors?.countryCodes}
                  required={!fk.values.countryCodes.length}
                  availableOptions={
                    availableCountryCodes
                      ? notAddedCountries.map((country) => country.code)
                      : undefined
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="currency"
                  label={'Currency'}
                  value={fk.values.currency}
                  onChange={(e) => fk.setFieldValue('currency', e.target.value)}
                  options={[
                    { value: 'USD', label: 'USD' },
                    { value: 'EUR', label: 'EUR' },
                  ]}
                  disabled={isAvailableForEditing('regional', 'currency')}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="addSageRoles"
                  label={'SGM Providers Role'}
                  value={fk.values.addSageRoles}
                  multiple
                  onChange={(e) => fk.setFieldValue('addSageRoles', e.target.value)}
                  options={[
                    { value: SAGE_ROLES.PRO, label: 'PRO' },
                    { value: SAGE_ROLES.ADVANCED, label: 'ADVANCED' },
                    { value: SAGE_ROLES.TRIAL, label: 'TRIAL' },
                    { value: SAGE_ROLES.BASIC, label: 'BASIC' },
                    { value: SAGE_ROLES.CRYPTO_STARTER, label: 'LITE' },
                    { value: SAGE_ROLES.FOREX_BASIC, label: 'FOREX BASIC' },
                    { value: SAGE_ROLES.FOREX_ADVANCED, label: 'FOREX ADVANCED' },
                    { value: SAGE_PROVIDER_ROLES.CRYPTO, label: 'CRYPTO' },
                    { value: SAGE_PROVIDER_ROLES.CURRENCIES, label: 'CURRENCIES' },
                    { value: SAGE_PROVIDER_ROLES.INDICES, label: 'INDICES' },
                    { value: SAGE_PROVIDER_ROLES.SYNTHETIC, label: 'SYNTHETIC' },
                  ]}
                  disabled={isAvailableForEditing('regional', 'addSageRoles')}
                />
              </Grid>

              {createInputField('setupPrice', 'Setup Price*', 6, 'number')}
              {createInputField('uiOriginalPrice', 'UI Original Price', 6, 'number')}
              {createInputField('cv', 'CV', 6, 'number')}
              {createInputField('qv', 'QV', 6, 'number')}
              {createInputField('scv', 'SCV', 6, 'number')}
              {createInputField('sqv', 'SQV', 6, 'number')}
              {createInputField('matrixBV', 'Matrix BV', 6, 'number')}
              {!fk.values.fsbEnabled && createInputField('fsb', 'FSB', 6, 'number')}

              {createInputField('priority', 'Bundle priority', 6, 'number')}
              {createInputField('uiPriority', 'Bundle UI priority', 6, 'number')}
              {createInputField('rewardingDays', 'Rewarding Days', 6, 'number')}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="status"
                  label={'Status'}
                  value={fk.values.status}
                  onChange={(e) => fk.setFieldValue('status', e.target.value)}
                  disabled={isAvailableForEditing('regional', 'status')}
                  options={[
                    { value: 'active', label: 'Active' },
                    { value: 'inactive', label: 'Inactive' },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {createInputField('ghlTag', 'GHL Tag', 6)}
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={6}>
                  <DateTimePicker
                    id={'resignDate'}
                    fullWidth
                    label="Resign Agreement Date"
                    format="dd-MM-yyyy:hh-mm"
                    value={fk.values.resignDate || new Date()}
                    onChange={(e) => handleDateChange(e)}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Gateways</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Gateways'}
                    variant="outlined"
                    multiple
                    value={fk.values.gatewayMethodIds}
                    onChange={(e) => fk.setFieldValue('gatewayMethodIds', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                    disabled={isAvailableForEditing('regional', 'serviceIDs')}
                  >
                    {gateways.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.gatewayMethodIds.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Services</InputLabel>
                  <Select
                    id="serviceIDs"
                    label={'Services'}
                    variant="outlined"
                    multiple
                    value={fk.values.serviceIDs}
                    onChange={(e) => fk.setFieldValue('serviceIDs', e.target.value)}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                    disabled={isAvailableForEditing('regional', 'serviceIDs')}
                  >
                    {services.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <Checkbox checked={fk.values.serviceIDs.includes(value)} />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Primary Service</InputLabel>
                  <Select
                    id="primaryServiceId"
                    label={'Services'}
                    variant="outlined"
                    value={fk.values.primaryServiceId}
                    onChange={(e) => fk.setFieldValue('primaryServiceId', e.target.value)}
                    input={<Input />}
                    // @ts-ignore
                    renderValue={(selected) => selected || initialPrimaryService[0]?.label}
                    MenuProps={MenuProps}
                    error={!!fk.errors?.primaryServiceId}
                    disabled={isAvailableForEditing('regional', 'primaryServiceId')}
                  >
                    {primaryServices.map(({ value, label }) => (
                      <MenuItem className={classes.textWrap} key={label} value={value}>
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!primaryServices.length && <span>No available services </span>}
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Recurring/Split Settings </FormLabel>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="isRecurring"
                          name="isRecurring"
                          checked={fk.values.isRecurring}
                          onChange={fk.handleChange}
                          disabled={isEdit || isAvailableForEditing('regional', 'isRecurring')}
                        />
                      }
                      label="Enable Recurring fields"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          color="primary"
                          id="isSplit"
                          name="isSplit"
                          checked={splitOption}
                          onChange={(e) => {
                            const flag = e.target.checked;
                            setSplitOption(flag);
                            if (!flag) {
                              fk.setValues({
                                ...fk.values,
                                splitSKU: '',
                                splitBaseValue: 0,
                                splitValues: 0,
                                splitFSB: 0,
                              });
                            }
                          }}
                        />
                      }
                      // disabled={isAvailableForEditing('regional', 'splitSKU')}
                      label="Show Splitting section"
                      labelPlacement="end"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>

              {splitOption && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Splitting section</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SKUSelect
                      id={'splitSKU'}
                      label={'Split SKU'}
                      value={fk.values.splitSKU}
                      onChange={fk.setFieldValue}
                      disabled={isAvailableForEditing('regional', 'splitSKU')}
                    />
                  </Grid>
                  {createInputField('splitBaseValue', 'Split Base Value', 6, 'number')}
                  {createInputField('splitValues', 'splitValues', 6, 'number')}
                  {createInputField('splitPeriod', 'splitPeriod', 6, 'number')}
                  {createInputField('splitFSB', 'splitFSB', 6, 'number')}
                  {createInputField('splitBaseRenewValue', 'Split Base Renew Value', 6, 'number')}
                </>
              )}

              {fk.values.isRecurring && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Recurring Info</Typography>
                  </Grid>
                  {createInputField('recurringPrice', 'Recurring Price', 6, 'number')}
                  {createInputField('rcv', 'Recurring CV', 6, 'number')}
                  {createInputField('rqv', 'Recurring QV', 6, 'number')}
                  {createInputField('rscv', 'Recurring SCV', 6, 'number')}
                  {createInputField('rsqv', 'Recurring SQV', 6, 'number')}
                  {createInputField('rfsb', 'Recurring FSB', 6, 'number')}{' '}
                  {createInputField('rmatrixBV', 'Requring Matrix BV', 6, 'number')}
                  <Grid item xs={12}>
                    <Typography color="primary">Recurring Charge</Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="position"
                        name="recurringChargeType"
                        value={fk.values.recurringChargeType}
                        onChange={(e) => fk.setFieldValue('recurringChargeType', e.target.value)}
                      >
                        <FormControlLabel
                          value={PLAN_RECURRING_CHARGE_TYPE.DEFAULT}
                          control={<Radio color="primary" />}
                          label="by usual flow"
                          disabled={isAvailableForEditing('regional', 'recurringChargeType')}
                        />
                        <FormControlLabel
                          value={PLAN_RECURRING_CHARGE_TYPE.STRICT_FROM_PLAN_PRICE}
                          control={<Radio color="primary" />}
                          label="always by plan recurring price"
                          disabled={isAvailableForEditing('regional', 'recurringChargeType')}
                        />
                        <FormControlLabel
                          value={PLAN_RECURRING_CHARGE_TYPE.STRICT_FROM_PLAN_PRICE_UNTIL_EXPANDS}
                          control={<Radio color="primary" />}
                          label="by plan recurring price until first subscription expanding with addon, then by usual flow"
                          disabled={isAvailableForEditing('regional', 'recurringChargeType')}
                        />
                        <FormControlLabel
                          value={
                            PLAN_RECURRING_CHARGE_TYPE.STRICT_FROM_PLAN_PRICE_UNTIL_EXPANDS_BASED_ON_SERVICE_LIST
                          }
                          control={<Radio color="primary" />}
                          label="by plan recurring price until first subscription expanding with addon based on hardcoded SKU list, then by usual flow"
                          disabled={isAvailableForEditing('regional', 'recurringChargeType')}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    direction={'row'}
                    alignItems={'center'}
                    className={classes.checkbox}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          id={'autopayable'}
                          checked={fk.values.autopayable}
                          onChange={fk.handleChange}
                          name="autopayable"
                          color="primary"
                          disabled={isAvailableForEditing('regional', 'autopayable')}
                        />
                      }
                      label="Autopayable"
                    />
                  </Grid>
                </>
              )}

              {fk.values.hasRetail && (
                <>
                  <Grid item xs={12}>
                    <Typography color="primary">Retail Info</Typography>
                  </Grid>
                  {createInputField('retailExigoItem', 'Retail Exigo Item', 6, 'string')}
                  {fk.values.isRecurring && (
                    //! если только обa включены и показывать вместе с полями ретейл
                    <>
                      <Grid item xs={12}>
                        <Typography color="primary">Retail Recurring Info</Typography>
                      </Grid>

                      {createInputField(
                        'recurringRetailPrice',
                        'Recurring Retail Price',
                        6,
                        'number'
                      )}
                      {createInputField('retailRecurringCV', 'Retail Recurring CV', 6, 'number')}
                      {createInputField('retailRecurringQV', 'Retail Recurring QV', 6, 'number')}
                      {createInputField('retailRecurringSCV', 'Retail Recurring SCV', 6, 'number')}
                      {createInputField('retailRecurringSQV', 'Retail Recurring SQV', 6, 'number')}
                      {createInputField('retailRecurringFSB', 'Retail Recurring FSB', 6, 'number')}
                    </>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      id={'isCashbackActive'}
                      checked={fk.values.isCashbackActive}
                      onChange={handleCashbackToggle}
                      name="isCashbackActive"
                      color="primary"
                      disabled={isAvailableForEditing('regional', 'isCashbackActive')}
                    />
                  }
                  label="E-wallet cashback"
                />
              </Grid>
              {createInputField(
                'enrollerCashback',
                'Enroller cashback percent',
                6,
                'number',
                true,
                undefined,
                !fk.values.isCashbackActive
              )}
              {createInputField(
                'customerCashback',
                'Customer cashback percent',
                6,
                'number',
                true,
                undefined,
                !fk.values.isCashbackActive
              )}

              {createInputField('stripeCustomPlanId', 'Stripe Plan ID', 6, 'string')}
              {createInputField('trialPeriod', 'Trial Period', 6, 'number')}

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="exigoField1"
                  label={'CustomerExtended.field1'}
                  value={fk.values.exigoField1}
                  onChange={(e) => fk.setFieldValue('exigoField1', e.target.value)}
                  disabled={isAvailableForEditing('regional', 'exigoField1')}
                  options={[
                    { value: null, label: 'Set Default value' },
                    { value: '0', label: '0' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                  ]}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography color="primary">Bundle Style Settings</Typography>

                <FileInput
                  id={'imageUrl'}
                  label={'Bundle Background'}
                  imageUrl={fk.values.imageUrl}
                  fk={fk}
                  entity={IMAGE_UPLOAD_ENTITY.PLAN}
                />
              </Grid>

              {/*<Grid item xs={12} md={6}>*/}
              {/*  <IconMoonSelect id={'icon'} value={fk.values.icon} onChange={fk.setFieldValue} />*/}
              {/*</Grid>*/}

              {createInputField(
                'color',
                'Text color',
                6,
                'color',
                false,
                (e) => {
                  if (e?.target?.value) {
                    fk.setFieldValue('color', e.target.value);
                  }
                },
                false,
                '',
                fk.values.color || '#000'
              )}

              {createInputField(
                'bgColor',
                'Background Color',
                6,
                'color',
                false,
                (e) => {
                  if (e?.target?.value) {
                    fk.setFieldValue('bgColor', e.target.value);
                  }
                },
                false,
                '',
                fk.values.bgColor || '#000'
              )}
            </Grid>
            <Grid item xs={12} direction={'row'} alignItems={'center'}>
              <BulletDescriptions
                asHtmlRichText={true}
                onChange={(list: string[]) => fk.setFieldValue('bullets', list)}
                bullets={fk.values.bullets || []}
              />
            </Grid>
            {planForEditing?.id && <UpsellingRulesList planId={planForEditing?.id} />}

            {planForEditing?.id && (
              <Can perform={Permissions.readSwitchingRules}>
                <SwitchingRulesList
                  planId={planForEditing?.id}
                  services={services}
                  countries={fk.values.countryCodes}
                />
              </Can>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {createEditText}
            </Button>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditPlan;
