import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { passwordGenerator } from '@root/utils/passwor-generator';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import 'date-fns';
import { FormikProps, useFormik } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import BackButton from '../../components/BackButton';
import Can, { Permissions } from '../../components/Can';
import CountrySelect from '../../components/CountrySelect';
import CouponSummary from '../../components/CouponSummary';
import CouponUsageRules from '../../components/CouponUsageRules';
import { createInput, useStandardFormStyles } from '../../components/FormBase';
import InputSelect from '../../components/InputSelect';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import { createCountryCodeList } from '../../hooks/instances';
import createCoupon, { ICoupon } from '../../services/api/requests/createCoupon';
import editCoupon from '../../services/api/requests/editCoupon';
import { editCouponData } from '../../store/entities/actions';
import {
  selectAvailablePlansOptions,
  selectCouponForEditing,
  selectInstances,
} from '../../store/entities/selectors';
import setNotification from '../../utils/notifications';
import pageLinks from '../../utils/pageLinks';

const validationSchema = Yup.object().shape({
  title: Yup.string().max(200, "Value can't be more than 20").required(),
  couponCode: Yup.string().max(20, "Value can't be more than 20").required(),
  description: Yup.string().max(100, "Value can't be more than 10"),
  perUserLimit: Yup.number().min(1, 'Value must be be grater than 0'),
  cv: Yup.number().min(0, 'Value must be be grater than 0'),
  stock: Yup.number().required().min(1, 'Value must be be grater than 0'),
  qv: Yup.number().min(0, 'Value must be be grater than 0'),
  scv: Yup.number().min(0, 'Value must be be grater than 0'),
  sqv: Yup.number().min(0, 'Value must be be grater than 0'),
  fsb: Yup.number().min(0, 'Value must be be grater than 0'),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 250,
    },
  },
};

const dateNow = new Date();

interface IOption {
  value: any;
  label: string;
}

export const actions: IOption[] = [
  { value: 'RENEW', label: 'Renew' },
  { value: 'INITIAL', label: 'Buy' },
  { value: 'AFFILIATE_UPGRADE', label: 'Affiliate Upgrade' },
  { value: 'all', label: 'All' },
];

const initialValues: ICoupon = {
  couponCode: '',
  title: '',
  description: '',
  userId: null,
  perUserLimit: 1,
  stock: 1,
  countries: [],
  startAt: dateNow,
  expiredAt: null,
  autopay: true,
  elite: false,
  excludeAddonDiscount: false,
  published: false,
  allowCrossUsage: false,
  isForBundleSwitch: false,
  planIds: [],
  discount: 0,
  currency: 'USD',
  forAction: 'ANY',
  secret: '*2Vqxg7E]uGoJy%',
  cv: 0,
  qv: 0,
  scv: 0,
  sqv: 0,
  fsb: 0,
  matrixBV: 0,
  splitBaseValue: 0,
};

const CreateEditCoupon: React.FC = () => {
  const classes = useStandardFormStyles();
  const availablePlansOptions = useSelector(selectAvailablePlansOptions);
  const dispatch = useDispatch();
  const instances = useSelector(selectInstances);
  const couponForEditing = useSelector(selectCouponForEditing);
  const isEdit = !!couponForEditing;

  const fk: FormikProps<ICoupon> = useFormik({
    initialValues: couponForEditing
      ? {
          ...initialValues,
          ...couponForEditing,
          forAction: couponForEditing.forAction || 'all',
          cv: couponForEditing.cv || 0,
          qv: couponForEditing.qv || 0,
          scv: couponForEditing.scv || 0,
          sqv: couponForEditing.sqv || 0,
          fsb: couponForEditing.fsb || 0,
          matrixBV: couponForEditing.matrixBV || 0,
          splitBaseValue: couponForEditing.splitBaseValue || 0,
        }
      : initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: ICoupon) => {
      try {
        const { countries } = createCountryCodeList({
          selectedValues: values.countries,
          instances,
        });

        const cvQvFieldsData =
          values.currency === '%'
            ? {
                // REMOVED THIS DATA FROM REQUEST
                cv: undefined,
                qv: undefined,
                scv: undefined,
                sqv: undefined,
                fsb: undefined,
                matrixBV: undefined,
              }
            : {};

        const data = {
          ...values,
          planIds: values.planIds.filter((planId: string) => !(planId === 'all')),
          countries,
          ...cvQvFieldsData,
          // startAt: startOfDay(values.startAt as Date),
          startAt: values.startAt
            ? `${new Date(values.startAt).toISOString()}`.split('T')[0]
            : `${new Date().toISOString()}`.split('T')[0],
          // expiredAt: endOfDay(values.expiredAt as Date),
          expiredAt: values.expiredAt
            ? `${new Date(values.expiredAt).toISOString()}`.split('T')[0]
            : null,
          splitBaseValue: values.splitBaseValue || null,
        };

        await saveCouponData(data as ICoupon);
        setNotification('success', { message: 'Success' });
        if (couponForEditing?.id) {
          dispatch(push(pageLinks.coupons));
        }
      } catch (e) {
        console.warn('COUPON_ERROR', e);
      }
    },
  });

  const saveCouponData = async (data: ICoupon): Promise<void> => {
    const response: AxiosResponse<{ data: ICoupon }> = couponForEditing?.id
      ? await editCoupon({
          ...data,
          id: couponForEditing?.id as number,
          userId: data.userId || null,
        })
      : await createCoupon({ ...data, userId: data.userId || null });
    dispatch(editCouponData(response.data.data));
  };

  const saveCouponAndOpenRulesPanel = async (): Promise<void> => {
    fk.handleSubmit();
  };

  const createInputField = createInput<ICoupon>(fk);

  const generateCode = () => {
    const code = passwordGenerator.generate({
      length: 10,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    });
    fk.setFieldValue('couponCode', code, false);
  };

  const createEditText = isEdit ? 'Edit' : 'Create';

  return (
    <Grid item xs={12}>
      <BackButton name={'Back'} link={pageLinks.coupons} margin={'0 0 10px 0'} />
      <Paper className={classes.paper}>
        <Title>{createEditText} Coupon </Title>
        <CssBaseline />
        <div className={classes.formContainer}>
          <form className={classes.form} onSubmit={fk.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete={'couponCode'}
                  name={'couponCode'}
                  variant="outlined"
                  fullWidth
                  id={'couponCode'}
                  type={'text'}
                  label={'Coupon Code'}
                  error={!!(fk.errors.couponCode && fk.touched.couponCode)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.couponCode && fk.errors.couponCode}
                  value={fk.values.couponCode}
                  onChange={fk.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={generateCode} edge="end">
                          <CachedIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {createInputField('title', 'Title')}
              {createInputField('description', 'Description')}
              {createInputField('userId', 'UserId', 6, 'number')}
              {createInputField('perUserLimit', 'PerUserLimit', 6, 'number')}
              {createInputField('stock', 'Stock', 6, 'number')}

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    id={'startAt'}
                    fullWidth
                    label="Start At"
                    format="dd-MM-yyyy"
                    value={fk.values.startAt}
                    minDate={isEdit ? fk.values.startAt : dateNow}
                    onChange={(e) => fk.setFieldValue('startAt', e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    id={'expiredAt'}
                    fullWidth
                    label="Expired At"
                    format="dd-MM-yyyy"
                    minDate={fk.values.expiredAt ? dateNow : null}
                    value={fk.values.expiredAt}
                    onChange={(e) => fk.setFieldValue('expiredAt', e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Plans</InputLabel>
                  <Select
                    id="planIds"
                    label={'Plans'}
                    variant="outlined"
                    multiple
                    value={fk.values.planIds}
                    onChange={(e, j: any): void => {
                      const currValue = j?.props?.value;
                      const currValues = e.target.value;
                      const prevValues = fk.values.planIds;

                      const planIds =
                        currValue === 'all'
                          ? prevValues.includes('all')
                            ? []
                            : ['all']
                          : //@ts-ignore
                            currValues.filter((planId: string) => !(planId === 'all'));

                      fk.setFieldValue('planIds', planIds);
                    }}
                    input={<Input />}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {availablePlansOptions.map(({ value, label }) => (
                      <MenuItem key={label} value={value}>
                        <Checkbox
                          checked={fk.values.planIds.some(
                            (item) => item?.toString() === value.toString()
                          )}
                        />
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <CountrySelect
                  id={'countries'}
                  value={fk.values.countries}
                  onChange={fk.setFieldValue}
                  withGroups
                  required={!fk.values.countries?.length}
                  error={!!(fk.errors.couponCode && fk.touched.couponCode)}
                  onBlur={fk.handleBlur}
                  helperText={fk.touched.couponCode ? fk.errors.couponCode : ''}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="forAction"
                  label={'For Action'}
                  value={fk.values.forAction}
                  onChange={(e) => fk.setFieldValue('forAction', e.target.value)}
                  options={actions}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputSelect
                  id="currency"
                  label={'Currency'}
                  value={fk.values.currency}
                  onChange={(e) => fk.setFieldValue('currency', e.target.value)}
                  options={[
                    { value: 'USD', label: 'USD' },
                    { value: '%', label: '%' },
                  ]}
                />
              </Grid>

              {createInputField('discount', 'Discount', 6, 'number')}

              {fk.values.currency === 'USD' && (
                <>
                  {createInputField('cv', 'cv', 6, 'number')}
                  {createInputField('qv', 'qv', 6, 'number')}
                  {createInputField('scv', 'scv', 6, 'number')}
                  {createInputField('sqv', 'sqv', 6, 'number')}
                  {createInputField('fsb', 'fsb', 6, 'number')}
                  {createInputField('matrixBV', 'Matrix BV', 6, 'number')}
                  {createInputField('splitBaseValue', 'Split Base Value', 6, 'number')}
                </>
              )}
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'elite'}
                      checked={fk.values.elite}
                      onChange={fk.handleChange}
                      name="elite"
                      color="primary"
                    />
                  }
                  label="Is Elite"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'published'}
                      checked={fk.values.published}
                      onChange={fk.handleChange}
                      name="published"
                      color="primary"
                    />
                  }
                  label="Published"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'allowCrossUsage'}
                      checked={fk.values.allowCrossUsage}
                      onChange={fk.handleChange}
                      name="allowCrossUsage"
                      color="primary"
                    />
                  }
                  label="Can be used with another coupon"
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'isForBundleSwitch'}
                      checked={fk.values.isForBundleSwitch}
                      onChange={fk.handleChange}
                      name="isForBundleSwitch"
                      color="primary"
                    />
                  }
                  label="Allow for a bundle switch"
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                direction={'row'}
                alignItems={'center'}
                className={classes.checkbox}
              >
                <FormControlLabel
                  control={
                    <Switch
                      id={'excludeAddonDiscount'}
                      checked={fk.values.excludeAddonDiscount}
                      onChange={fk.handleChange}
                      name="excludeAddonDiscount"
                      color="primary"
                    />
                  }
                  label="Do not apply with a membership discount"
                />
              </Grid>

              <Grid item xs={12}>
                {couponForEditing?.id ? (
                  <Can perform={Permissions.readCouponUsageRules}>
                    <Typography color="primary">Usage Rules</Typography>
                    <InputLabel className={classes.rulesDescription}>
                      In order to use coupon customer should have <strong>every</strong>{' '}
                      subscription of <strong>some</strong> usage rule
                    </InputLabel>

                    <InputLabel className={classes.rulesDescription}>
                      <strong>User coupon usage description: </strong>
                      {couponForEditing.usageRulesDescription}
                    </InputLabel>
                    <CouponUsageRules couponId={couponForEditing.id} />
                  </Can>
                ) : (
                  <div>
                    <Typography color="primary">
                      Usage Rules will be available after you save main coupon data
                    </Typography>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={saveCouponAndOpenRulesPanel}
                      disabled={!couponForEditing?.id}
                    >
                      Create usage rules
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>

            <Modal
              button={
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {createEditText}
                </Button>
              }
              renderContent={(onClose: any) => (
                <Paper>
                  <CouponSummary
                    onSubmit={fk.submitForm}
                    onClose={onClose}
                    formValues={fk.values}
                  />
                </Paper>
              )}
            />
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default CreateEditCoupon;
