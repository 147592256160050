import { Box, TextareaAutosize } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import * as React from 'react';
import Modal from '../Modal';

interface IProps {
  name?: string;
  title?: string;

  onOk(description: string): void;

  status?: string;
  message?: string;
}

const useStyles = makeStyles(() => ({
  boxButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
  },
}));

const RefundOrderModal: React.FC<IProps> = ({ onOk, message, status }) => {
  const classes = useStyles();
  const alreadyCanceled = status === 'Canceled';
  const [description, setDescription] = React.useState<string>(
    alreadyCanceled ? (message as string) : ''
  );

  return (
    <Modal
      button={
        <IconButton aria-label="delete" title="Mark as Refunded">
          <MoneyOffIcon />
        </IconButton>
      }
      renderContent={(onClose: () => void) => (
        <div>
          {alreadyCanceled ? (
            <h2>Your order has already been canceled. Please check the message below.</h2>
          ) : (
            <h2> Are you sure you want to mark this order as refunded?</h2>
          )}

          <TextareaAutosize
            style={{ resize: 'none', padding: '5px 10px', width: '100%' }}
            aria-disabled={true}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            minRows={3}
            placeholder="Please enter a short description"
            required
            disabled={alreadyCanceled}
          />

          {!alreadyCanceled && (
            <Box className={classes.boxButton}>
              <Button variant="contained" color="default" onClick={() => onClose()}>
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={description.length < 5 || alreadyCanceled}
                onClick={() => {
                  onOk(description);
                  onClose();
                }}
              >
                Yes
              </Button>
            </Box>
          )}
        </div>
      )}
    />
  );
};

export default RefundOrderModal;
