// @ts-ignore
import axios, { AxiosInstance } from 'axios';
import onAxiosError from '../../utils/errorHandler';
import setNotification from '../../utils/notifications';
import ApiUrls, { APIs } from './apiUrls';
import refreshToken from './requests/refreshToken';
import { getTokens, setTokens } from './tokenHandler';

const noTokenUrls = [ApiUrls.signIn, ApiUrls.forgotPassword, ApiUrls.resetPassword];

// const ipInstance = new ClientIp();

const instance: AxiosInstance = axios.create({
  baseURL: APIs.MAIN_URL,
  responseType: 'json',
});

let sessionExpired: boolean = false;

// Request interceptor for API calls
instance.interceptors.request.use(
  async (config) => {
    let token = '';
    if (
      APIs.LOG_SERVER_URL &&
      config.url?.includes(APIs.LOG_SERVER_URL) &&
      config.url !== `${APIs.LOG_SERVER_URL}auth`
    ) {
      token = localStorage.getItem('LAT') as string;
    } else if (!token) {
      token = (
        config.url === ApiUrls.refreshToken ? getTokens().refreshToken : getTokens().accessToken
      ) as string;
    }

    // @ts-ignore
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error) => {
    console.log('error', error);
    Promise.reject(error);
  }
);

//? Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    try {
      const originalRequest = error.config;
      const noTokenRequest = noTokenUrls.includes(originalRequest.url);

      console.warn('error', error);

      if (error.response.status === 500 && originalRequest.url === `${APIs.LOG_SERVER_URL}auth`) {
        userLogout();
        return;
      }

      if (!noTokenRequest && !getTokens().refreshToken && !sessionExpired) {
        userLogout();
        return;
      }

      if (
        !noTokenRequest &&
        error.response.status === 401 &&
        getTokens().accessToken &&
        !originalRequest._retry &&
        originalRequest.url !== ApiUrls.refreshToken
      ) {
        originalRequest._retry = true;

        const response = await refreshToken();
        setTokens(response.data);
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.accessToken;
        return instance(originalRequest);
      }

      if (originalRequest.url === ApiUrls.refreshToken) {
        userLogout();
      }

      if (
        error?.response?.data?.message.includes('level2 already exist') ||
        error?.response?.data?.message.includes('level1 already exist')
      ) {
        return Promise.resolve({ data: { isExist: true } });
      }

      // convert blob response data to json and show error
      if (error.response.data instanceof Blob) {
        const responseData = await error.response.data.text();
        onAxiosError(JSON.parse(responseData));
        return Promise.reject(error);
      }

      !sessionExpired && onAxiosError(error.response.data);

      return Promise.reject(error);
    } catch (e) {
      return Promise.reject(error);
    }
  }
);

function userLogout() {
  sessionExpired = true;
  localStorage.clear();
  localStorage.setItem('urlBeforeLogout', window.location.pathname + window.location.search);
  setNotification('info', { message: 'Your session expired' });
  setTimeout(() => {
    window.location.href = window.location.origin + '/login';
  }, 1000);
}

export default instance;
