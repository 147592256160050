import ApiUrls from '../apiUrls';
import apiClient from '../client';
import { IResponseAxios } from '../types';

export interface IParams {
  id: number;
  serviceId: number;
}

const request = ({ id, serviceId }: IParams) => {
  const url = ApiUrls.addRequiredPlanToCouponUsageRule.replace('{id}', `${id}`);
  return apiClient.post<IResponseAxios>(url, { serviceId }).then((response) => response);
};

export default request;
