import { IServiceData } from '../../services/api/requests/services';

export interface IValues extends Omit<IServiceData, 'pointRewards' | 'questRewards'> {
  cv: number;
  qv: number;
  scv: number;
  sqv: number;
  fsb: number;
  rcv: number;
  rqv: number;
  rscv: number;
  matrixBV: number;
  rsqv: number;
  rfsb: number;
  rmatrixBV: number;
  INITIAL: number;
  RENEW: number;
}

export type RecurringTypeData = {
  recurringSkus: Record<string, string>;
  skus: Record<string, string>;
};

export enum SAGE_PROVIDER_ROLES {
  CURRENCIES = 'currencies provider',
  CRYPTO = 'crypto provider',
  SYNTHETIC = 'synthetic provider',
  INDICES = 'indices provider',
}
